import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import {
  parseCustomLabel,
  serializePlatformAccount,
  serializePlatformPage
} from 'utils/functions';
import pluralize from 'utils/pluralize';
import {
  ACTIVATEDAUDIENCES,
  ACTIVATION,
  CONNEXION,
  CUSTOMERS,
  FUNNEL,
  GROUP,
  IMPORTMODEL,
  INSIGHT,
  PROPERTIES,
  RAMSES_REPORTS,
  USERS
} from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { HelpTooltip, Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

function GroupContainer() {
  const [config, setConfig] = React.useState({});
  const [groupsList, setGroups] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [activationPresetsData, setActivationPresetData] = useState();
  const [insightTarget, setInsightTarget] = useState();
  const [importModel, setImportModel] = useState();
  const [connectorsConnexion, setConnectorsConnexion] = useState();
  const [activatedAudiences, setActivatedAudiences] = useState();
  const [properties, setProperties] = useState();
  const [ramsesReports, setRamsesReports] = useState();
  const [users, setUsers] = useState();
  const [funnels, setFunnels] = useState();
  const [allRows, setAllRows] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelectedGroup({});
  };
  const onDelete = () => {
    GROUP.deleteGroup(selectedGroup._id)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const selectGroup = id => {
    const group = groupsList.find(el => el._id === id);
    setSelectedGroup(group);
  };

  const getGroups = async () => {
    try {
      const result = await GROUP.getGroups({
        custom: ['key', 'customerName', 'name']
      });
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getPresets = async () => {
    try {
      const result = await ACTIVATION.getActivationPresets();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getInsight = async () => {
    try {
      const result = await INSIGHT.getInsight();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getActivatedAudiences = async () => {
    try {
      const result = await ACTIVATEDAUDIENCES.getActivatedAudiences();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };
  const formatUsers = (usersList = []) => {
    const nbUsers = usersList.length;
    if (nbUsers === 0) {
      return <Text>Aucun</Text>;
    }
    if (nbUsers === 1)
      return <Text color="global"> {usersList[0] && usersList[0].email} </Text>;

    const others = nbUsers - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'utilisateur',
      others
    )}`;

    const tooltipContent = () => {
      return (
        <Container>
          {usersList.map(user => getToolTipText(user.email))}
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="global"> {usersList[0].email}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="global">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatFunnels = funnelsList => {
    const nbFunnel = funnelsList.length;

    if (nbFunnel === 0) {
      return <Text>Aucun</Text>;
    }
    const str = `${nbFunnel} ${pluralize('funnel', nbFunnel)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>
              {funnelsList.map(funnel => getToolTipText(funnel.name))}
            </Item>
          </Row>
        </Container>
      );
    };

    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="funnel">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatActivationPresets = presets => {
    const nbPresets = presets.length;

    if (nbPresets === 0) {
      return <Text>Aucun</Text>;
    }
    const str = `${nbPresets} ${pluralize('paramétrage', nbPresets)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{presets.map(({ name }) => getToolTipText(name))}</Item>
          </Row>
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="activation">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatInsightPresets = presets => {
    const nbPresets = presets.length;

    if (nbPresets === 0) {
      return <Text>Aucun</Text>;
    }
    const str = `${nbPresets} ${pluralize('paramétrage', nbPresets)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{presets.map(({ name }) => getToolTipText(name))}</Item>
          </Row>
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="insight">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };
  const formatCustomLabel = str => {
    if (!str?.length) {
      return <Text>Aucun</Text>;
    }
    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <HelpTooltip title={str} />
          </Item>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    const getDatas = async () => {
      const [
        resp,
        resPreset,
        resInsight,
        resActiAudience,
        propertiesList,
        { funnels: funnelList },
        usersList,
        resImportModel,
        resConnectorsConnexion,
        resRamsesReports
      ] = await Promise.all([
        getGroups(),
        getPresets(),
        getInsight(),
        getActivatedAudiences(),
        PROPERTIES.get(),
        FUNNEL.getFunnels(['name']),
        USERS.getAll(),
        IMPORTMODEL.get(),
        CONNEXION.getConnexions(),
        RAMSES_REPORTS.get()
      ]);

      setConnectorsConnexion(
        resConnectorsConnexion.map(e => ({
          label: e.name,
          value: e._id
        }))
      );
      setImportModel(
        resImportModel.map(e => ({
          label: e.label,
          value: e.model
        }))
      );

      const mappedPreset = resPreset.map(e => ({
        name: e.name,
        _id: e._id
      }));
      const mappedInsight = resInsight.map(e => ({
        _id: e._id,
        name: e.name
      }));

      const mappedActiAudiences = resActiAudience.map(e => ({
        _id: e._id,
        name: e.name
      }));

      const mappedRamsesReports = resRamsesReports.map(e => ({
        _id: e._id,
        name: e.name
      }));

      const mapped = resp.map(item => {
        const newItem = {};

        const presetObj = item.activationPresetIds.map(
          id =>
            mappedPreset.find(el => el._id === id) || {
              name: 'unknowed',
              _id: id
            }
        );
        const insightObj = item.insightUserTarget.map(
          id =>
            mappedInsight.find(el => el._id === id) || {
              name: 'unknowed',
              _id: id
            }
        );
        const activAudiencesObj = item.activatedAudiences.map(
          id =>
            mappedActiAudiences.find(el => el._id === id) || {
              name: 'unknowed',
              _id: id
            }
        );
        const ramseReporstObj = item.ramsesReports.map(
          id =>
            mappedRamsesReports.find(el => el._id === id) || {
              name: 'unknowed',
              _id: id
            }
        );
        newItem._id = item._id;
        newItem.name = item.name;
        newItem.users = formatUsers(
          item.userIds?.reduce((acc, userId) => {
            const exist = usersList.find(({ _id }) => _id === userId);
            if (exist) {
              acc.push(exist);
            }
            return acc;
          }, [])
        );
        newItem.funnels = formatFunnels(
          item.funnelIds.reduce((acc, el) => {
            const exist = funnelList.find(({ id }) => id === el);
            if (exist) {
              const propertie =
                propertiesList.find(prop => prop.funnelIds.includes(el))
                  ?.name || 'Autres';
              acc.push({
                ...exist,
                name: `${propertie} > ${exist.name}`
              });
            }
            return acc;
          }, [])
        );
        newItem.activationPresets = formatActivationPresets(presetObj);
        newItem.insightTarget = formatInsightPresets(insightObj);
        newItem.activatedAudiences = formatInsightPresets(activAudiencesObj);
        newItem.ramsesReports = formatInsightPresets(ramseReporstObj);
        newItem.customLabel = formatCustomLabel(parseCustomLabel(item));

        return newItem;
      });
      setGroups(resp);
      setActivationPresetData(mappedPreset);
      setInsightTarget(mappedInsight);
      setActivatedAudiences(mappedActiAudiences);
      setRamsesReports(mappedRamsesReports);
      setFunnels(
        funnelList.map(el => {
          const propertie =
            propertiesList.find(prop => prop.funnelIds.includes(el._id))
              ?.name || 'Autres';
          return {
            ...el,
            name: `${propertie} > ${el.name}`
          };
        })
      );
      setProperties(propertiesList);
      setUsers(usersList);

      setRows(mapped);
      setAllRows(mapped);
      setLoaded(true);
    };

    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateGroup = data => {
    GROUP.create({
      name: data.name,
      presetId: data.presetId,
      userIds: data.selectedUsers.map(e => e._id),
      funnelIds: data.selectedFunnels.map(e => e._id),
      activationPresetIds: data.selectedActivationPresets.map(e => e._id),
      activatedAudiences: data.selectedActivatedAudiences.map(el => el._id),
      ramsesReports: data.selectedRamsesReports.map(el => el._id),
      platformAccounts: data.selectedPlatformAccounts.map(
        serializePlatformAccount
      ),
      platformPages: data.selectedPlatformPages.map(el =>
        serializePlatformPage(el)
      ),
      accountManagers: data.selectedAccountManagers.map(el => el._id),
      mandats: data.mandats,
      useFunnelDataInActivation: data.useFunnelDataInActivation,
      campaignCrm: {
        active: data.enableCRMCampaign,
        presetId: data.enableCRMCampaign ? data.crmPresetId : ''
      }
    }).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const handleUpdateGroup = data => {
    GROUP.updateV2(selectedGroup._id, {
      name: data.name !== selectedGroup.name ? data.name : undefined,
      presetId: data.presetId,
      funnelIds: data.selectedFunnels.map(el => el._id),
      userIds: data.selectedUsers.map(el => el._id),
      activationPresetIds: data.selectedActivationPresets.map(el => el._id),
      insightUserTarget: data.selectedInsightTarget.map(el => el._id),
      activatedAudiences: data.selectedActivatedAudiences.map(el => el._id),
      ramsesReports: data.selectedRamsesReports.map(el => el._id),
      platformAccounts: data.selectedPlatformAccounts.map(el =>
        serializePlatformAccount(el)
      ),
      platformPages: data.selectedPlatformPages.map(el =>
        serializePlatformPage(el)
      ),
      accountManagers: data.selectedAccountManagers.map(el => el._id),
      mandats: data.mandats,
      useFunnelDataInActivation: data.useFunnelDataInActivation,
      campaignCrm: {
        active: data.enableCRMCampaign,
        presetId: data.enableCRMCampaign ? data.crmPresetId : ''
      }
    }).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const addGroup = async () => {
    if (adminRights?.general?.groups?.create) {
      setConfig({
        funnels,
        users,
        properties,
        activationPresets: activationPresetsData,
        insightUserTarget: insightTarget,
        activatedAudiences,
        ramsesReports,
        importModel,
        connectorsConnexion
      });
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const updateGroup = async id => {
    if (adminRights?.general?.groups?.update) {
      selectGroup(id);
      const group = groupsList.find(f => f._id === id);
      const stripeCustomers = await CUSTOMERS.getStripeCustomerByDomain();
      setConfig({
        funnels,
        users,
        properties,
        id,
        activationPresets: activationPresetsData,
        insightUserTarget: insightTarget,
        activatedAudiences,
        ramsesReports,
        importModel,
        connectorsConnexion,
        stripeCustomers,
        canEditCustomer: adminRights?.general?.customers?.advanced,
        actualState: {
          ...group,
          selectedUsers:
            group.userIds?.reduce((acc, userId) => {
              const user = users.find(f => f._id === userId);
              if (user) {
                acc.push({ _id: user._id, email: user.email });
              }
              return acc;
            }, []) || [],
          selectedFunnels: group.funnelIds.reduce((acc, funnelId) => {
            const funnel = funnels.find(f => f._id === funnelId);
            if (funnel) {
              acc.push(funnel);
            }
            return acc;
          }, []),
          selectedActivationPresets: group.activationPresetIds.map(e =>
            activationPresetsData.find(f => f._id === e)
          ),
          selectedInsightTarget: group.insightUserTarget.map(e =>
            insightTarget.find(f => f._id === e)
          ),
          selectedActivatedAudiences: group.activatedAudiences.map(e =>
            activatedAudiences.find(f => f._id === e)
          ),
          selectedRamsesReports: group.ramsesReports.map(e =>
            ramsesReports.find(f => f._id === e)
          ),
          selectedPlatformPages: group.platformPages?.map(e => {
            const connector = connectorsConnexion.find(
              el => el.value === e.config.connexionId
            );
            return {
              name: e.name,
              media: e.media,
              connexionId: connector
                ? {
                    value: e.config.connexionId,
                    label: connector?.label
                  }
                : undefined,

              externalId: e.config.externalId,
              status: e.status
            };
          }),
          selectedPlatformAccounts: group.platformAccounts.map(e => {
            const connector = connectorsConnexion.find(
              el => el.value === e.config.connexionId
            );
            return {
              name: e.name,
              model: e.model,
              connexionId: connector
                ? {
                    value: e.config.connexionId,
                    label: connector?.label
                  }
                : undefined,
              externalId: e.config.externalId,
              status: e.status
            };
          }),
          selectedAccountManagers: group.accountManagers.reduce(
            (acc, userId) => {
              const user = users.find(f => f._id === userId);
              if (user) {
                acc.push({ _id: user._id, email: user.email });
              }
              return acc;
            },
            []
          ),
          enableCRMCampaign: group.campaignCrm.active,
          crmPresetId: group.campaignCrm.presetId || group.presetId
        }
      });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const deleteGroup = id => {
    if (adminRights?.general?.groups?.delete) {
      selectGroup(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };
  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche une organisation"
            placeholder="Nom, Utilisateur, Funnel ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addGroup} text="Ajouter une organisation" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onNext={handleCreateGroup}
              config={config}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Nom', id: 'name', sortKey: 'name' },
                {
                  label: 'Utilisateurs',
                  id: 'users',
                  style: { color: 'funnel' }
                },
                { label: 'Funnels', id: 'funnels' },
                { label: 'Paramètres activation', id: 'activationPresets' },
                { label: 'Sauvegardes Audiences', id: 'insightTarget' },
                { label: 'Mes audiences activée', id: 'activatedAudiences' },
                {
                  label: 'Liens',
                  id: 'customLabel',
                  help:
                    'client (nom) < organisation (nom) > PML groupements / magasins (key)'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={updateGroup}
              onDelete={deleteGroup}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onNext={handleUpdateGroup}
              config={config}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedGroup.name}
              type="cette organisation"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
}

export default GroupContainer;
